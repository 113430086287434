<template>
  <div class="poster-wrap">
    <img
      src="https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/update/poster.png"
      alt=""
    />
    <div class="text">{{ desList[index].des }}</div>
    <div class="share">点击右上角可分享至朋友圈</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      desList: [
        {
          title: this.$t("workSpace.update.title1"),
          des: this.$t("workSpace.update.des1"),
        },
        {
          title: this.$t("workSpace.update.title2"),
          des: this.$t("workSpace.update.des2"),
        },
        {
          title: this.$t("workSpace.update.title3"),
          des: this.$t("workSpace.update.des3"),
        },
        {
          title: this.$t("workSpace.update.title4"),
          des: this.$t("workSpace.update.des4"),
        },
        {
          title: this.$t("workSpace.update.title5"),
          des: this.$t("workSpace.update.des5"),
        },
        {
          title: this.$t("workSpace.update.title6"),
          des: this.$t("workSpace.update.des6"),
        },
        {
          title: this.$t("workSpace.update.title7"),
          des: this.$t("workSpace.update.des7"),
        },
      ],
    };
  },
  mounted() {
    this.index = this.$route.query.index;
  },
};
</script>

<style lang="scss" scoped>
.poster-wrap {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  width: 100%;
  img {
    width: 100%;
  }
  .text {
    position: absolute;
    width: 70%;
    text-align: left;
    top: 80%;
    left: 10px;
    color: #fff;
    font-size: 16px;
  }
  .share {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: -15px;
    background-color: #edf6fb;
  }
}
</style>
